import React from "react"

import Title from 'components/Title'
import Constrain from 'components/Constrain'
import Video from 'components/Video'

import Container from 'components/Container'

import image from 'images/chat.png'
import { useSiteMetadata } from "../../hooks/useSiteMetadata"

import { useTranslation } from 'gatsby-plugin-react-i18next'
import Footer from "components/Footer"
import Header from "components/Header"

export default function Home() {

  const { t } = useTranslation()

  const { title } = useSiteMetadata()

  return (
    <>
    <Header />
    <main>
      <Title 
        title={ t('centralizedMessaging', 'Centralized messaging') } 
        subtitle={ t('One inbound phone number shared between all your devices') }
        dark 
      />
      <Constrain>
        <Video poster={ image } src='https://autoservice.nyc3.cdn.digitaloceanspaces.com/media/chat.mp4?v=3' />
      </Constrain>

      <Container>

        <h2>{ t('Never give out your cell phone number again') }</h2>

        <p>
          { t('Instantly provision a virtual phone number with a local area code for inbound and outbound SMS messaging.') }
        </p>
        <p>
            { t('Send and receive SMS messages directly within {{ siteTitle }} from any device.', { siteTitle: title }) } 
        </p>

      </Container>
  </main>
  <Footer />
  </>
  )
}
